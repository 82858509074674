.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px dotted lightgrey;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 10rem;
}
.social{
    display: flex;
    gap: 4rem;
}
.social>img{
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    border-radius: 10%;
    opacity: 1;
}
.info>span{
    color: white ;
    font-weight: bold;
    font-size: medium;
}
/* .logo-footer>img{
    width: 10rem;
    height: 10rem;
} */