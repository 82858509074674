body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: orange;
}
.bg-img{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 210%;
  filter: brightness(10%);
  z-index: -1;
}
@media screen and (max-width : 768px){
  .bg-img{
    position: absolute;
    height: 300%;
    width:1200px;
  }
}