.index{
    display: flex;
    background-color:  transparent;
  }
  .left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }
  
  .the-ad{
    margin-top: 4rem;
    background-color: rgba(0, 0, 0, 0.551);
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .the-ad>span{
    z-index: 2;
  }
  .the-ad>div{
    position: absolute;
    background-color: orange;
    width: 25%;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
  }
  .mid-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    color: white;
    font-weight: bold;
    text-overflow: inherit;
  }
  
  .figures{
    display: flex;
    gap: 2rem;
  }
  .figures>div{
    display: flex;
    flex-direction: column;
  }
  .figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
  }
  .figures>div>span:nth-of-type(2){
    color: grey;
    text-transform: uppercase;
  }
  .btn{
    background-color: white;
    padding: 0.5rem;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
  }
  .btn:hover{
    cursor:pointer;
  }
  .mid-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
  }
  .mid-buttons>:nth-child(1)
  {
    color: white;
    background-color: orange;
    width: 8rem;
  }
  .mid-buttons>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid orange;
  }
  .right-h{
    flex: 1;
    position: relative;
    background-color:transparent;
  }
  .right-h>:nth-child(1){
    position: absolute;
    background-color: transparent;
    border: 2px solid white; 
    right: 20% ;
    top: 2rem;
    height: 3rem;
    width: 6rem;
    color: white;
  }
  .right-h>:nth-child(2){
    position: absolute;
    background-color: transparent;
    border: 2px solid white;
    left:20%;
    top: 2rem;
    width: 6rem;
    height: 3rem;
    color: white;
  }
  .mid-img{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
    opacity: 0.9;
    border-radius: 50%;
  }
  @media screen and (max-width : 768px)
{
  .index{
    flex-direction: column;
  }
  .the-ad{
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }
  .mid-text{
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  /* .mid-text>div:nth-of-type(3)
  {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  } */
  .mid-buttons{
    justify-content: center;
  }
  .figures{
    justify-content: center;
  }
  .figures>div>span:nth-of-type(1){
    font-size: large;
  }
  .figures>div>span:nth-of-type(2){
    font-size: small;
  }
  .right-h{
    position: relative;
    background: none;
  }
  .mid-img{
    position: relative;
    width: 15rem;
    left: 4rem;
    top: 5rem;
    align-self:center;
    justify-content: center;

  }
}