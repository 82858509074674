.header{
    display:flex;
    justify-content: space-between;
}

.logo{
    width:10rem;
    height:3rem;
}
.header-menu{
    list-style: none;
    display:flex;
    gap: 3rem;
    color: white;
}
.header-menu>li{
    cursor: pointer;
    font-size: 1.5rem;
}
.header-menu>li:hover{
    cursor: pointer;
    color: orange;
}
@media screen and (max-width : 768px){
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        /* background-color: var(--appColor); */
    }
}